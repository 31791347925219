.mainModal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: none;
    z-index: 10000;
    background: rgba(0,0,0,.5);
}

.mainModalContent {
    padding: 25px;
    background: white;
    border-radius: 6px;
    min-width: 250px;
    z-index: 10000;
}

.mainModal.active {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mainModalClose {
    width: 15px;
    height: 15px;
    fill: #444;
    cursor: pointer;
}